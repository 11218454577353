import { Line, LineItem, ToolTip } from "../TasksGoals";
import { Input } from "../../UI/input/Input";

const TaskItem = ({
    column,
    notFact,
    success,
    disable,
    value,
    plane,
    onChange,
    name,
}) => {
    return (
        <Line
            column={column}
            style={{
                backgroundColor: success ? "#92ff92" : "transparent",
            }}
        >
            {!notFact && (
                <LineItem>
                    <span>Факт:</span>
                    <Input
                        style={{ height: "28px" }}
                        disabled={disable}
                        value={value}
                        onChange={onChange}
                    />
                </LineItem>
            )}

            <LineItem>
                <span>План:</span>
                {plane}
            </LineItem>
            <LineItem>
                <span>Задача:</span>
                <p>{name}</p>
                <ToolTip>{name}</ToolTip>
            </LineItem>
        </Line>
    );
};

export default TaskItem;
