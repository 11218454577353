import styled from "@emotion/styled";

import { useState, useEffect } from "react";

import dayjs from "dayjs";

import Modal from "../UI/modal/Modal";
import Chart from "../chart/Chart";
import request from "../../api/request";

const ModalChart = (props) => {
    const [salesChart, setSalesChart] = useState([]);
    const [consultationChart, setConsultationChart] = useState([]);
    const [leadsChart, setLeadsChart] = useState([]);
    const [moneyBoxChart, setMoneyBoxChart] = useState([]);
    const getChart = async () => {
        try {
            const response = await request({ endPoint: `graph/${props.id}` });
            return response.data;
        } catch {
            return [];
        }
    };
    useEffect(() => {
        if (props.id) {
            getChart().then((data) => {
                const salesData = [];
                const consultationData = [];
                const leadsData = [];
                const moneyBoxData = [];
                data.map((item) => {
                    salesData.push({
                        day: dayjs(item.date).format("DD-MM-YYYY"),
                        type: "sales",
                        city: "План",
                        value: item.sales,
                    });
                    salesData.push({
                        day: dayjs(item.date).format("DD-MM-YYYY"),
                        type: "sales_fact",
                        city: "Факт",
                        value: item.sales_fact,
                    });
                    consultationData.push({
                        day: dayjs(item.date).format("DD-MM-YYYY"),
                        type: "consultation",
                        city: "План",
                        value: item.consultation,
                    });
                    consultationData.push({
                        day: dayjs(item.date).format("DD-MM-YYYY"),
                        type: "consultation_fact",
                        city: "Факт",
                        value: item.consultation_fact,
                    });
                    leadsData.push({
                        day: dayjs(item.date).format("DD-MM-YYYY"),
                        type: "leads",
                        city: "План",
                        value: item.leads,
                    });
                    leadsData.push({
                        day: dayjs(item.date).format("DD-MM-YYYY"),
                        type: "leads_fact",
                        city: "Факт",
                        value: item.leads_fact,
                    });
                    moneyBoxData.push({
                        day: dayjs(item.date).format("DD-MM-YYYY"),
                        type: "money_in_the_box",
                        city: "План",
                        value: item.money_in_the_box,
                    });
                    moneyBoxData.push({
                        day: dayjs(item.date).format("DD-MM-YYYY"),
                        type: "money_in_the_box_fact",
                        city: "Факт",
                        value: item.money_in_the_box_fact,
                    });
                });
                setSalesChart(salesData);
                setConsultationChart(consultationData);
                setLeadsChart(leadsData);
                setMoneyBoxChart(moneyBoxData);
            });
        }
    }, [props.id]);
    return (
        <Modal {...props}>
            <Body>
                <ChartBody>
                    <p>Продажи</p>
                    <Chart data={salesChart} />
                </ChartBody>
                <ChartBody>
                    <p>Консультация\Диагностика</p>
                    <Chart data={consultationChart} />
                </ChartBody>
                <ChartBody>
                    <p>Лиды\Прозвоны</p>
                    <Chart data={leadsChart} />
                </ChartBody>
                <ChartBody>
                    <p>Деньги в кассе</p>
                    <Chart data={moneyBoxChart} />
                </ChartBody>
            </Body>
        </Modal>
    );
};

export default ModalChart;

const Body = styled.div`
    width: 90vw;
    height: 90vh;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-template-rows: 47% 47%;
`;

const ChartBody = styled.div`
    width: 100%;
    height: 100%;
    padding: 12px;
    p {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 4px;
    }
`;
