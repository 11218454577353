function formatNumber(num) {
    if (num) {
        const number = num.toString().replace(/\s/g, "");
        var separator = " ";
        return number.replace(
            /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g,
            "$1" + separator
        );
    } else {
        return "";
    }
}

export default formatNumber;
