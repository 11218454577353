import styled from "@emotion/styled";

import { useState, useEffect } from "react";

import dayjs from "dayjs";

import request from "../../api/request";

import { BiLineChart } from "react-icons/bi";

import SwitchDate from "../../components/switchDate/SwitchDate";
import UserInfo from "../userInfo/UserInfo";
// import Task from "../../components/task/Task";
import Accordion from "../../components/UI/accordion/Accordion";
import TasksGoals from "../../components/tasksGoals/TasksGoals";

import ModalChart from "../modalChart/ModalChart";

const Goals = ({ user, userId }) => {
    const [date, setDate] = useState(new Date(Date.now()));
    const [activeItem, setActiveItem] = useState();
    const [goals, setGoals] = useState([]);
    const [chartId, setChartId] = useState(false);
    const editGoals = (task) => {
        const newGoals = Array.from(goals);
        const editGoal = newGoals.find((goal) =>
            goal.objective_task.find((obj_task) => obj_task.id === task.id)
        );
        const oldTask = editGoal.objective_task.find(
            (obj_task) => obj_task.id === task.id
        );
        const indexTask = editGoal.objective_task.indexOf(oldTask);
        editGoal.objective_task.splice(indexTask, 1, task);
        setGoals(newGoals);
    };
    const getGoals = async () => {
        try {
            setGoals([]);
            const response = await request({
                endPoint: "objectives",
                params: `?date=${dayjs(date).format("YYYY-MM-DD")}${
                    userId ? `&user=${userId}` : ""
                }`,
                method: "get",
            });
            return response.data;
        } catch {
            return [];
        }
    };
    useEffect(() => {
        getGoals().then((data) => setGoals(data));
    }, [date]);
    return (
        <>
            <SwitchDate date={date} setDate={setDate} />
            <Body>
                {user && <UserInfo user={user} />}
                {goals?.map((goal) => (
                    <Accordion
                        key={goal.id}
                        open={goal.id === activeItem}
                        setOpen={() => setActiveItem(goal.id)}
                        setClose={() => setActiveItem(false)}
                        header={
                            <>
                                <Name>{goal.name}</Name>{" "}
                                <BiLineChart
                                    size={28}
                                    style={{
                                        marginLeft: "auto",
                                        marginRight: "12px",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setChartId(goal.id)}
                                />
                            </>
                        }
                    >
                        {goal?.objective_task?.map((task) => (
                            <TasksGoals
                                key={task.id}
                                disable={
                                    user ||
                                    dayjs(date).format("YYYY-MM-DD") !==
                                        dayjs(new Date(Date.now())).format(
                                            "YYYY-MM-DD"
                                        )
                                        ? true
                                        : false
                                }
                                task={task}
                                editGoals={editGoals}
                            />
                        ))}
                    </Accordion>
                ))}
            </Body>
            <ModalChart
                open={chartId}
                setClose={() => setChartId(false)}
                id={chartId}
            />
        </>
    );
};

export default Goals;

const Body = styled.div`
    max-width: 1440px;
    width: 100%;
    padding: 32px 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const Name = styled.p`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
`;

export { Body, Name };
