import { Formik } from "formik";
import * as yup from "yup";

import { Input } from "../UI/input/Input";
import Button from "../UI/button/Button";
import { Form, Error } from "../formRegistration/FormRegistration";

import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/reducers/userReducer";

import request from "../../api/request";
import showToast from "../../helpers/showToast";

const FormLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const login = async (data) => {
        try {
            const response = await request({
                endPoint: "login",
                method: "post",
                data,
            });
            localStorage.setItem("access", response.data.access);
            localStorage.setItem("refresh", response.data.refresh);
            dispatch(setAuth("load"));
            showToast("Успешный вход", "success");
            navigate("/");
        } catch (error) {
            showToast(error.response.data.detail, "error");
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            dispatch(setAuth(false));
        }
    };
    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email("Некорректный email")
            .required("Обязательное поле"),
        password: yup.string().required("Обязательное поле"),
    });
    return (
        <>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validateOnBlur
                onSubmit={(values) => {
                    login(values);
                }}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isValid,
                    handleSubmit,
                }) => (
                    <Form autoComplete="nope" onSubmit={handleSubmit}>
                        <Input
                            type={"email"}
                            name={"email"}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите адрес электронной почты"
                        />
                        {touched.email && errors.email && (
                            <Error>{errors.email}</Error>
                        )}
                        <Input
                            type={"password"}
                            name={"password"}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите пароль"
                        />
                        {touched.password && errors.password && (
                            <Error>{errors.password}</Error>
                        )}
                        <Button
                            disabled={!isValid}
                            type={"submit"}
                            style={{ width: "100%", marginTop: "12px" }}
                        >
                            Войти
                        </Button>
                    </Form>
                )}
            </Formik>
            <p>или</p>
            <Link to={"/registration"}>Зарегистрироваться</Link>
        </>
    );
};

export default FormLogin;
