import ModalAddGoal from "../../components/modalAddGoal/ModalAddGoal";
import { useNavigate } from "react-router-dom";

const AddGoal = () => {
    const navigate = useNavigate();
    return (
        <>
            <ModalAddGoal open={true} setClose={() => navigate("/")} />
        </>
    );
};

export default AddGoal;
