import { Routes, Route } from "react-router-dom";

import Header from "../components/UI/header/Header";
import Main from "../pages/main/Main";
import User from "../pages/user/User";
import AddGoal from "../pages/addGoal/AddGoal";
import PlanWeek from "../pages/planWeek/PlanWeek";

const AuthRouter = () => {
    return (
        <>
            <Header />
            <Routes>
                <Route path={"/"} element={<Main />} />
                <Route path="users/:id" element={<User />} />
                <Route path="add-goal" element={<AddGoal />} />
                <Route path="week" element={<PlanWeek />} />
            </Routes>
        </>
    );
};

export default AuthRouter;
