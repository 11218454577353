import styled from "@emotion/styled";

const UserInfo = ({ user }) => {
    return (
        <div>
            <Name>{user.fio}</Name>
            <Link href={`tel:${user.phone_number}`}>{user.phone_number}</Link>
            <br />
            <Link href={`mailto: ${user.email}`}>{user.email}</Link>
            <Role>Роль: {user.role}</Role>
        </div>
    );
};

export default UserInfo;

const Name = styled.p`
    font-size: 24px;
    font-weight: 700;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const Link = styled.a`
    display: inline-block;
    font-weight: 300;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Role = styled.p`
    font-weight: 400;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
