import styled from "@emotion/styled";

import WeekItem from "./weekItem/WeekItem";

import { useState, useEffect } from "react";
import request from "../../api/request";

const Week = () => {
    const [dates, setDates] = useState([]);
    const [goals, setGoals] = useState([]);
    const [activeItem, setActiveItem] = useState(false);
    const getDates = async () => {
        try {
            const response = await request({
                endPoint: "dates",
                method: "get",
            });
            return response.data.dates;
        } catch {
            return [];
        }
    };
    const getGoals = async (date) => {
        try {
            const response = await request({
                endPoint: "objectives",
                params: `?date=${date}`,
                method: "get",
            });

            return response.data;
        } catch {
            return [];
        }
    };
    useEffect(() => {
        getDates().then((data) => setDates(data));
    }, []);
    useEffect(() => {
        dates.map((date, index) => {
            getGoals(date).then((data) => {
                setGoals((prev) => {
                    const dateNum = +date.replace(/-/gi, "");
                    const newGoals = [
                        ...prev,
                        {
                            id: index,
                            date: date,
                            date_num: dateNum,
                            goals: data,
                        },
                    ];
                    newGoals.sort((a, b) => {
                        if (a.date > b.date) {
                            return 1;
                        }
                        if (a.date < b.date) {
                            return -1;
                        }
                        return 0;
                    });
                    return newGoals;
                });
            });
        });
    }, [dates]);
    return (
        <Body>
            {goals.length >= 5 && (
                <>
                    {goals.map((item) => (
                        <WeekItem
                            key={item.id}
                            date={item.date}
                            data={item.goals}
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                        />
                    ))}
                </>
            )}
        </Body>
    );
};

export default Week;

const Body = styled.div`
    max-width: 90vw;
    width: 100%;
    padding: 32px 16px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    @media (max-width: 1024px) {
        max-width: 100vw;
        grid-template-columns: 100%;
        grid-row-gap: 16px;
        padding: 32px 16px;
    }
`;
