import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import request from "../../api/request";

import { Container } from "../main/Main";
import Goals from "../../components/goals/Goals";

const User = () => {
    const location = useLocation();
    const [userId, setUserId] = useState(false);
    const [user, setUser] = useState(false);
    const getUser = async () => {
        try {
            const response = await request({
                endPoint: `user/${userId}`,
                method: "get",
            });
            return response.data;
        } catch {}
    };
    useEffect(() => {
        if (userId) {
            getUser().then((data) => setUser(data));
        }
    }, [userId]);
    useEffect(() => {
        setUserId(location.pathname.slice(7));
    }, []);
    return (
        <Container>
            {user && (
                <>
                    <Goals user={user} userId={userId} />
                </>
            )}
        </Container>
    );
};

export default User;
