import styled from "@emotion/styled/macro";

import { keyframes } from "@emotion/react";

import { useState } from "react";

import ButtonUI from "../UI/button/Button";
import TaskItem from "./taskItem/TaskItem";

import request from "../../api/request";

import showToast from "../../helpers/showToast";
import formatNumber from "../../helpers/formatNumber";
import changeNumber from "../../helpers/changeNumber";

const TasksGoals = ({ disable, task, editGoals, column, notFact }) => {
    const [leadsFact, setLeadsFact] = useState(
        task?.leads_fact ? task?.leads_fact?.toString() : ""
    );
    const [consultationFact, setConsultationFact] = useState(
        task?.consultation_fact ? task?.consultation_fact?.toString() : ""
    );
    const [salesFact, setSalesFact] = useState(
        task?.sales_fact ? task?.sales_fact.toString() : ""
    );
    const [moneyInTheBoxFact, setMoneyInTheBoxFact] = useState(
        task?.money_in_the_box_fact
            ? task?.money_in_the_box_fact?.toString()
            : ""
    );
    const [disabled, setDisabled] = useState(false);
    const patchTask = async (e) => {
        try {
            e.preventDefault();
            setDisabled(true);
            const response = await request({
                endPoint: "objectives-task",
                params: `${task.id}/`,
                method: "patch",
                data: {
                    leads_fact: +leadsFact.replace(/\s/g, ""),
                    consultation_fact: +consultationFact.replace(/\s/g, ""),
                    sales_fact: +salesFact.replace(/\s/g, ""),
                    money_in_the_box_fact: +moneyInTheBoxFact.replace(
                        /\s/g,
                        ""
                    ),
                },
            });
            const newTask = { ...task };
            newTask.leads_fact = +leadsFact.replace(/\s/g, "");
            newTask.consultation_fact = +consultationFact.replace(/\s/g, "");
            newTask.sales_fact = +salesFact.replace(/\s/g, "");
            newTask.money_in_the_box_fact = +moneyInTheBoxFact.replace(
                /\s/g,
                ""
            );
            editGoals(newTask);
            showToast("Успешное сохранение", "success");
            return response.data;
        } catch {
            showToast("Что-то пошло не так", "error");
        } finally {
            setDisabled(false);
        }
    };
    return (
        <form onSubmit={patchTask}>
            <Body>
                <Line hideMobile column={column}>
                    <LineItem>Факт</LineItem>
                    <LineItem>План</LineItem>
                    <LineItem>Задача</LineItem>
                </Line>
                <TaskItem
                    column={column}
                    notFact={notFact}
                    success={+leadsFact.replace(/\s/g, "") >= task.leads}
                    disable={disable}
                    value={formatNumber(leadsFact)}
                    plane={formatNumber(task.leads)}
                    onChange={(e) => setLeadsFact(changeNumber(e.target.value))}
                    name={<>Лиды\Прозвоны (5%)</>}
                />
                <TaskItem
                    column={column}
                    notFact={notFact}
                    success={
                        +consultationFact.replace(/\s/g, "") >=
                        task.consultation
                    }
                    disable={disable}
                    value={formatNumber(consultationFact)}
                    plane={formatNumber(task.consultation)}
                    onChange={(e) =>
                        setConsultationFact(changeNumber(e.target.value))
                    }
                    name={<>Консультация\Диагностика (30%)</>}
                />
                <TaskItem
                    column={column}
                    notFact={notFact}
                    success={+salesFact.replace(/\s/g, "") >= task.sales}
                    disable={disable}
                    value={formatNumber(salesFact)}
                    plane={formatNumber(task.sales)}
                    onChange={(e) => setSalesFact(changeNumber(e.target.value))}
                    name={<>Продажи (30%)</>}
                />
                <TaskItem
                    column={column}
                    notFact={notFact}
                    success={
                        +moneyInTheBoxFact.replace(/\s/g, "") >=
                        task.money_in_the_box
                    }
                    disable={disable}
                    value={formatNumber(moneyInTheBoxFact)}
                    plane={formatNumber(task.money_in_the_box)}
                    onChange={(e) =>
                        setMoneyInTheBoxFact(changeNumber(e.target.value))
                    }
                    name={<>Деньги в кассе</>}
                />
                {disable ? (
                    <></>
                ) : (
                    <Button disabled={disabled}>Сохранить</Button>
                )}
            </Body>
        </form>
    );
};

export default TasksGoals;

const Body = styled.div`
    width: 100%;
`;

const show = keyframes`
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
`;

const ToolTip = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    background: black;
    border-radius: 4px;
    padding: 2px;
    color: white;
    transform: translateY(-100%);
    display: none;
    animation: ${show} 0.5s ease;
    z-index: 99;
`;

const LineItem = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    &:last-child {
        p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }
        width: calc(100% - 400px);
    }
    span {
        font-weight: 600;
        margin-right: 4px;
        display: none;
    }
    @media (max-width: 768px) {
        width: 100%;
        border-right: none !important;
        &:last-child {
            width: 100%;
        }
        &:hover {
            ${ToolTip} {
                display: block;
            }
        }
        span {
            display: inline;
        }
    }
`;

const Line = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.column ? "column-reverse" : "row")};
    border-top: ${(props) => (props.column ? "2px solid #131432" : "none")};
    &:first-child {
        display: ${(props) => (props.column ? "none" : "flex")};
    }
    &:not(:last-child) {
        margin-bottom: 4px;
    }
    @media (max-width: 768px) {
        border-top: 2px solid #131432;
        &:first-child {
            display: ${(props) => (props.hideMobile ? "none" : "flex")};
        }
        &:not(:last-child) {
            border-top: 2px solid #131432;
        }
        flex-direction: column-reverse;
    }
    ${LineItem} {
        width: ${(props) => (props.column ? "100%" : "200px")};
        padding: ${(props) => (props.column ? "4px 0" : "0 8px")};
        span {
            display: ${(props) => (props.column ? "inline" : "none")};
        }
        &:not(:last-child) {
            border-right: ${(props) =>
                props.column ? "none" : "2px solid #131432"};
        }
        &:last-child {
            width: ${(props) => (props.column ? "100%" : "calc(100% - 400px)")};
        }
        @media (max-width: 768px) {
            width: 100%;
            padding: 4px 0;
            span {
                display: inline;
            }
        }
    }
`;

const Button = styled(ButtonUI)`
    padding: 8px;
    width: 100%;
    background-color: #2ecc71;
    margin-top: 12px;
    &:hover {
        background-color: #27ae60;
    }
`;

export { Line, LineItem, ToolTip };
