import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import { GrClose } from "react-icons/gr";

const Modal = ({ open, setClose, children }) => {
    return (
        <>
            {open && (
                <Overlay onClick={setClose}>
                    <Body onClick={(e) => e.stopPropagation()}>
                        <Close onClick={setClose}>
                            <GrClose size={22} />
                        </Close>
                        {children}
                    </Body>
                </Overlay>
            )}
        </>
    );
};

export default Modal;

const showOverlay = keyframes`
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
`;

const showBody = keyframes`
    from {
        transform: scale(0);
    } to {
        transform: scale(1);
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 20;
    animation: ${showOverlay} 0.3s ease;
    padding: 0 16px;
`;

const Body = styled.div`
    position: relative;
    padding: 12px;
    border-radius: 6px;
    background: white;
    animation: ${showBody} 0.3s ease;
`;

const Close = styled.button`
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-50%);
    width: 32px;
    height: 32px;
    background: white;
    border: none;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px gray;
`;
