import FormBody from "../../components/UI/formBody/FormBody";
import FormLogin from "../../components/formLogin/FormLogin";

const Login = () => {
    return (
        <FormBody name="Вход">
            <FormLogin />
        </FormBody>
    );
};

export default Login;
