import styled from "@emotion/styled";

import dayjs from "dayjs";

import { Name } from "../../goals/Goals";
import Accordion from "../../UI/accordion/Accordion";
import TasksGoals from "../../tasksGoals/TasksGoals";

const WeekItem = ({ date, data, activeItem, setActiveItem }) => {
    return (
        <Body>
            <Date>{dayjs(date).format("DD.MM.YYYY")}</Date>
            {data.map((goal) => (
                <Accordion
                    key={goal.id}
                    open={`${goal.id}-${date}` === activeItem}
                    setOpen={() => setActiveItem(`${goal.id}-${date}`)}
                    setClose={() => setActiveItem(false)}
                    header={<Name>{goal.name}</Name>}
                >
                    {goal?.objective_task?.map((task) => (
                        <TasksGoals
                            key={task.id}
                            disable={true}
                            task={task}
                            column
                            notFact
                        />
                    ))}
                </Accordion>
            ))}
        </Body>
    );
};

export default WeekItem;

const Body = styled.div`
    width: 100%;
    min-height: 80vh;
    padding: 4px;
    &:not(:last-child) {
        border-right: 2px solid #131432;
    }
    @media (max-width: 1024px) {
        border-right: none !important;
        min-height: auto;
    }
`;

const Date = styled.p`
    font-weight: 600;
    font-size: 20px;
`;
