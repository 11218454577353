import styled from "@emotion/styled";

const Button = styled.button`
    cursor: pointer;
    display: block;
    padding: 12px 24px;
    background-color: #131432;
    border: none;
    border-radius: 4px;
    color: white;
    font-weight: 700;
    transition: 300ms;
    &:hover {
        background-color: #484964;
    }
    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;

export default Button;
