import styled from "@emotion/styled";

import Modal from "../UI/modal/Modal";
import FormAddGoal from "../formAddGoal/FormAddGoal";

const ModalAddGoal = (props) => {
    return (
        <Modal {...props}>
            <Body>
                <Title>Добавить цель</Title>
                <FormAddGoal close={props.setClose} />
            </Body>
        </Modal>
    );
};

export default ModalAddGoal;

const Body = styled.div`
    width: 500px;
    @media (max-width: 768px) {
        width: calc(100vw - 64px);
    }
`;

const Title = styled.p`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 12px;
`;
