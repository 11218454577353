import { useEffect, useState } from "react";

import Accordion from "../../UI/accordion/Accordion";
import { Line, LineItem } from "../../tasksGoals/TasksGoals";

import { Link } from "react-router-dom";

import request from "../../../api/request";

const Group = ({ open, group, setOpen, setClose }) => {
    const [users, setUsers] = useState();
    const getGroup = async () => {
        try {
            const response = await request({
                endPoint: `groups/${group.id}`,
                method: "get",
            });
            return response.data;
        } catch {
            return [];
        }
    };
    useEffect(() => {
        if (open) {
            getGroup().then((data) => setUsers(data));
        } else {
            setUsers([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    return (
        <Accordion
            key={group.id}
            open={open}
            setOpen={setOpen}
            setClose={setClose}
            header={<>{group.name}</>}
        >
            <Line>
                <LineItem>Факт</LineItem>
                <LineItem>План</LineItem>
                <LineItem>Пользователь</LineItem>
            </Line>
            {users?.map((user) => (
                <Line>
                    <LineItem>{user.money_in_the_box_fact}</LineItem>
                    <LineItem>{user.money_in_the_box}</LineItem>
                    <LineItem>
                        <Link to={`/users/${user.pk}`}>{user.name}</Link>
                    </LineItem>
                </Line>
            ))}
        </Accordion>
    );
};

export default Group;
