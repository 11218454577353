import { Container } from "../main/Main";
import Week from "../../components/week/Week";

const PlanWeek = () => {
    return (
        <Container>
            <Week />
        </Container>
    );
};

export default PlanWeek;
