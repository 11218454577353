import styled from "@emotion/styled";

import InputMaskLib from "react-input-mask";

const Input = styled.input`
    font-size: 14px;
    width: 100%;
    background-color: #fafbfc;
    border: 2px solid #dfe1e6;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    height: 40px;
    -webkit-transition: background-color 0.2s ease-in-out 0s,
        border-color 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s,
        border-color 0.2s ease-in-out 0s;
    &:focus {
        outline: none;
        background-color: white;
        border: 2px solid #131432;
        box-shadow: 0 0 0;
    }
    &:disabled {
        cursor: not-allowed;
    }
`;

const InputMask = styled(InputMaskLib)`
    font-size: 14px;
    width: 100%;
    background-color: #fafbfc;
    border: 2px solid #dfe1e6;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    height: 40px;
    -webkit-transition: background-color 0.2s ease-in-out 0s,
        border-color 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s,
        border-color 0.2s ease-in-out 0s;
    &:focus {
        outline: none;
        background-color: white;
        border: 2px solid #131432;
        box-shadow: 0 0 0;
    }
`;

export { Input, InputMask };
