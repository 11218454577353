import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import { useState, useRef } from "react";
import CalendarLib from "react-calendar";

import { FaCalendarAlt } from "react-icons/fa";

import useOnClickOutside from "../../hooks/useOnClickOutside";

const Calendar = ({ date, setDate }) => {
    const [open, setOpen] = useState(false);
    const ref = useRef();
    useOnClickOutside(ref, () => setOpen(false));
    const handleDate = (e) => {
        setDate(e);
        setTimeout(() => setOpen(false), 500);
    };
    return (
        <Body ref={ref}>
            <FaCalendarAlt
                size={24}
                onClick={() => setOpen(!open)}
                style={{ cursor: "pointer" }}
            />
            {open && (
                <Content>
                    <CalendarLib onChange={handleDate} value={date} />
                </Content>
            )}
        </Body>
    );
};

export default Calendar;

const show = keyframes`
    from {
        transform: translate(-50%, 100%) scale(0);
    }
    to {
        transform: translate(-50%, 100%) scale(1);
    }
`;

const Body = styled.div`
    position: relative;
    width: min-content;
    @media (max-width: 768px) {
        position: static;
    }
`;

const Content = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    animation: ${show} 0.2s ease;
    z-index: 10;
    @media (max-width: 768px) {
        bottom: auto;
        top: 0;
        left: 50%;
        animation: none;
        transform: translateX(-50%);
        /* width: min-content;
        height: min-content; */
    }
`;
