import styled from "@emotion/styled";

import dayjs from "dayjs";

import { Formik } from "formik";
import * as yup from "yup";

import { Input } from "../UI/input/Input";
import Button from "../UI/button/Button";
import { Form, Error } from "../formRegistration/FormRegistration";
import Calendar from "../calendar/Calendar";

import request from "../../api/request";
import showToast from "../../helpers/showToast";

import formatNumber from "../../helpers/formatNumber";
import changeNumber from "../../helpers/changeNumber";

const FormAddGoal = ({ close }) => {
    const validationSchema = yup.object().shape({
        name: yup.string().required("Обязательное поле"),
        date_start_objective: yup.string().required("Обязательное поле"),
        date_end_objective: yup.string().required("Обязательное поле"),
        cost: yup.number().required("Обязательное поле"),
        average_check: yup.number().required("Обязательное поле"),
    });
    const addGoal = async (data) => {
        try {
            data.cost = +data.cost.replace(/\s/g, "");
            data.average_check = +data.average_check.replace(/\s/g, "");
            await request({
                endPoint: "objectives",
                method: "post",
                data,
            });
            showToast(`${data.name} успешно добавлена!`, "success");
            close();
        } catch (error) {
            showToast("Что-то пошло не так!", "error");
        }
    };
    return (
        <Formik
            initialValues={{
                name: "",
                date_start_objective: "",
                date_end_objective: "",
                cost: "",
                average_check: "",
            }}
            validateOnBlur
            onSubmit={(values) => {
                addGoal(values);
            }}
            validationSchema={validationSchema}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isValid,
                handleSubmit,
                setFieldValue,
            }) => (
                <Form autoComplete="nope" onSubmit={handleSubmit}>
                    <Input
                        type={"text"}
                        name={"name"}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Название цели"
                    />
                    {touched.name && errors.name && (
                        <Error>{errors.name}</Error>
                    )}
                    <Flex>
                        <Input
                            disabled={true}
                            type={"text"}
                            name={"date_start"}
                            value={
                                values.date_start_objective &&
                                dayjs(values.date_start_objective).format(
                                    "DD.MM.YYYY"
                                )
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Дата начала"
                        />
                        <Calendar
                            setDate={(value) =>
                                setFieldValue(
                                    "date_start_objective",
                                    dayjs(value).format("YYYY-MM-DD")
                                )
                            }
                        />
                    </Flex>
                    {touched.date_start_objective &&
                        errors.date_start_objective && (
                            <Error>{errors.date_start_objective}</Error>
                        )}
                    <Flex>
                        <Input
                            disabled={true}
                            type={"text"}
                            name={"date_end_objective"}
                            value={
                                values.date_end_objective &&
                                dayjs(values.date_end_objective).format(
                                    "DD.MM.YYYY"
                                )
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Дата завершения"
                        />
                        <Calendar
                            setDate={(value) =>
                                setFieldValue(
                                    "date_end_objective",
                                    dayjs(value).format("YYYY-MM-DD")
                                )
                            }
                        />
                    </Flex>
                    {touched.date_end_objective &&
                        errors.date_end_objective && (
                            <Error>{errors.date_end_objective}</Error>
                        )}
                    <Input
                        name={"cost"}
                        value={formatNumber(values.cost)}
                        onChange={(e) =>
                            setFieldValue("cost", changeNumber(e.target.value))
                        }
                        onBlur={handleBlur}
                        placeholder="Стоимость цели"
                    />
                    {touched.cost && errors.cost && (
                        <Error>{errors.cost}</Error>
                    )}
                    <Input
                        name={"average_check"}
                        value={formatNumber(values.average_check)}
                        onChange={(e) =>
                            setFieldValue(
                                "average_check",
                                changeNumber(e.target.value)
                            )
                        }
                        onBlur={handleBlur}
                        placeholder="Средний чек"
                    />
                    {touched.average_check && errors.average_check && (
                        <Error>{errors.average_check}</Error>
                    )}
                    <Button
                        disabled={!isValid}
                        type={"submit"}
                        style={{ width: "100%", marginTop: "12px" }}
                    >
                        Добавить цель
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default FormAddGoal;

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
`;
