import styled from "@emotion/styled/macro";

import { useNavigate } from "react-router-dom";

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setAuth, setUserData } from "../../../redux/reducers/userReducer";

import { GiHamburgerMenu } from "react-icons/gi";
import { FaTelegramPlane } from "react-icons/fa";
import { BiFootball } from "react-icons/bi";

import ButtonUI from "../button/Button";
import Menu from "./menu/Menu";
import MenuMobile from "./menuMobile/MenuMobile";

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const user = useSelector((store) => store.user.data);
    const logoutUser = () => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        dispatch(setAuth(false));
        dispatch(setUserData(false));
    };
    return (
        <>
            <Container>
                <Body>
                    <Logo onClick={() => navigate("/")}>
                        <BiFootball size={26} />
                        Target = Goal
                    </Logo>
                    <Telegram
                        size={26}
                        onClick={() => window.open(user.url, "_blank")}
                    />
                    <Name>{user.fio}</Name>
                    <Button onClick={logoutUser}>Выйти</Button>
                    <Burger size={32} onClick={() => setOpen(!open)} />
                </Body>
                <Sidebar>
                    <Menu />
                </Sidebar>
            </Container>
            <MenuMobile open={open} setOpen={setOpen} logoutUser={logoutUser} />
        </>
    );
};

export default Header;

const Sidebar = styled.div`
    background: #131432;
    height: 32px;
    overflow: hidden;
    @media (max-width: 768px) {
        display: none;
    }
`;

const Container = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #131432;
    z-index: 10;
`;

const Body = styled.div`
    z-index: 10;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    @media (max-width: 768px) {
        height: 52px;
        padding: 0 12px;
    }
    border-bottom: 1px solid white;
`;

const Logo = styled.p`
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-size: 32px;
    font-weight: 700;
    color: #e4bb69;
    transition: 300ms;
    &:hover {
        text-shadow: 1px 1px 2px gray;
    }
    @media (max-width: 768px) {
        font-size: 26px;
    }
`;

const Name = styled.p`
    color: white;
    letter-spacing: 0.5px;
    @media (max-width: 768px) {
        display: none;
    }
`;

const Telegram = styled(FaTelegramPlane)`
    cursor: pointer;
    color: white;
    margin-left: auto;
    margin-right: 12px;
`;

const Button = styled(ButtonUI)`
    background: white;
    color: black;
    margin-left: 12px;
    @media (max-width: 768px) {
        display: none;
    }
    &:hover {
        background: white;
        box-shadow: 1px 1px 4px gray;
    }
`;

const Burger = styled(GiHamburgerMenu)`
    color: white;
    margin-left: auto;
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;
