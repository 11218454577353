import styled from "@emotion/styled";

import { useSelector } from "react-redux";

import ButtonUI from "../../button/Button";

import { Link as LinkDom } from "react-router-dom";

const MenuMobile = ({ open, setOpen, logoutUser, addTask }) => {
    const user = useSelector((store) => store.user.data);
    return (
        <Container open={open} onClick={() => setOpen(false)}>
            {open && (
                <>
                    <Name>{user.fio}</Name>
                    {/* <Link to="/">Главная</Link>
                    <Link to="/">Цели</Link> */}
                    {user.role === "Пользователь" && (
                        <>
                            <Link to="/">Главная</Link>
                            <Link to="/add-goal">Добавить цель</Link>
                            <Link to="/week">План на неделю</Link>
                        </>
                    )}
                </>
            )}
            <Button style={{ marin: "0" }} onClick={logoutUser}>
                Выйти
            </Button>
        </Container>
    );
};

export default MenuMobile;

const Container = styled.div`
    display: none;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    background: #131432;
    z-index: 9;
    transition: 200ms;
    padding: ${(props) => (props.open ? "64px 12px 64px 12px" : "0")};
    flex-direction: column;
    align-items: center;
    height: ${(props) => (props.open ? "100vh" : "0vh")};
    @media (max-width: 768px) {
        display: flex;
    }
`;

const Name = styled.p`
    position: relative;
    letter-spacing: 1px;
    color: white;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding-bottom: 4px;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            white 51.04%,
            rgba(255, 255, 255, 0) 100%
        );
    }
`;

const Link = styled(LinkDom)`
    color: white;
    margin-top: 12px;
`;

const Button = styled(ButtonUI)`
    width: 100%;
    margin-top: 12px;
    padding: 8px;
    background: white;
    color: black;
`;
