import styled from "@emotion/styled";

import dayjs from "dayjs";

import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

import Calendar from "../calendar/Calendar";

const SwitchDate = ({ date, setDate }) => {
    const IncDecDate = (type) => {
        switch (type) {
            case "inc":
                setDate(dayjs(date).add(1, "day").toDate());
                break;
            case "dec":
                setDate(dayjs(date).subtract(1, "day").toDate());
                break;
            default:
                setDate(date);
        }
    };
    return (
        <Body>
            <Button onClick={() => IncDecDate("dec")}>
                <MdArrowBackIos size={28} />
            </Button>
            <Center>
                {dayjs(date).format("DD.MM.YYYY")}
                <Calendar date={date} setDate={setDate} />
            </Center>
            <Button onClick={() => IncDecDate("inc")}>
                <MdArrowForwardIos size={28} />
            </Button>
        </Body>
    );
};

export default SwitchDate;

const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid white;
    border-bottom: 1px solid #131432;
`;

const Button = styled.button`
    cursor: pointer;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: #131432;
    color: white;
    transition: 300ms;
    padding: 6px 0;
    &:hover {
        background: #484964;
    }
`;

const Center = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 12px;
`;
