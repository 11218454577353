import FormBody from "../../components/UI/formBody/FormBody";
import FormRegistration from "../../components/formRegistration/FormRegistration";

const Registration = () => {
    return (
        <FormBody name="Регистрация">
            <FormRegistration />
        </FormBody>
    );
};

export default Registration;
