import {
    Chart as ChartBiz,
    Axis,
    Tooltip,
    Legend,
    LineAdvance,
    Point,
} from "bizcharts";

const Chart = ({ data }) => {
    const cols = {
        value: {
            tickCount: 10,
            range: [0, 0.99],
            min: 0,
            nice: true,
        },
        day: {
            range: [0, 1],
        },
    };
    return (
        <ChartBiz data={data} scale={cols} autoFit>
            <Axis name="value" />
            <Axis name="day" />
            <LineAdvance
                position="day*value"
                shape="unset"
                area
                color={["city", ["#e74c3c", "#3498db"]]}
                tooltip={[
                    `value*type*day`,
                    (value, type, day) => {
                        if (
                            type === "sales" ||
                            type === "consultation" ||
                            type === "leads" ||
                            type === "money_in_the_box"
                        ) {
                            type = "План";
                        } else if (
                            type === "sales_fact" ||
                            type === "consultation_fact" ||
                            type === "leads_fact" ||
                            type === "money_in_the_box_fact"
                        ) {
                            type = "Факт";
                        }
                        return {
                            value: value
                                ? value
                                      .toString()
                                      .replace(
                                          /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g,
                                          "$1 "
                                      )
                                : 0,
                            name: type,
                            title: day,
                            // title: oneDate ? data[0].day : day,
                        };
                    },
                ]}
            />
            <Point
                position="day*value"
                color={["city", ["#e74c3c", "#3498db"]]}
            />
            <Tooltip shared={true} showCrosshairs />
            <Legend
                name="city"
                onChange={(e) => {
                    const { item, view } = e;
                    const legend =
                        view.getController("legend").components[0].component;
                    const items = legend.getItems();
                    view.filter("city", (city) => {
                        debugger;
                        if (
                            items.some(
                                (it) => it.value !== item.value && it.unchecked
                            )
                        ) {
                            return true;
                        } else {
                            return city === item.value;
                        }
                    });
                    view.render();
                }}
            />
        </ChartBiz>
    );
};

export default Chart;
