import { useEffect } from "react";

import AuthRouter from "./routes/AuthRouter";
import NotAuthRouter from "./routes/NotAuthRouter";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useSelector, useDispatch } from "react-redux";
import { setAuth, setUserData } from "./redux/reducers/userReducer";

import { useLocation, useNavigate } from "react-router-dom";

import request from "./api/request";

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((store) => store.user.auth);
    const getUser = async () => {
        try {
            if (localStorage.getItem("access")) {
                const response = await request({
                    endPoint: "me",
                    method: "get",
                });
                dispatch(setAuth(true));
                dispatch(setUserData(response.data));
            } else {
                dispatch(setAuth(false));
                dispatch(setUserData(false));
            }
        } catch (error) {
            dispatch(setAuth(false));
            dispatch(setUserData(false));
        }
    };
    useEffect(() => {
        const access = new URLSearchParams(location.search).get("access");
        const refresh = new URLSearchParams(location.search).get("refresh");
        if (access && refresh) {
            localStorage.setItem("access", access);
            localStorage.setItem("refresh", refresh);
            navigate("/");
        }
        if (auth === "load") {
            getUser();
        }
        if (
            !auth &&
            auth !== "load" &&
            location.pathname !== "/login" &&
            location.pathname !== "/login-tg"
        ) {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, [location.pathname]);
    return (
        <>
            {auth !== "load" ? (
                auth ? (
                    <AuthRouter />
                ) : (
                    <NotAuthRouter />
                )
            ) : (
                <></>
            )}
            <ToastContainer />
        </>
    );
}

export default App;
