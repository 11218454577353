import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import { BiDownArrow } from "react-icons/bi";

const Accordion = ({ open, setOpen, setClose, header, children }) => {
    const handleClick = () => {
        if (open) {
            setClose();
        } else {
            setOpen();
        }
    };
    return (
        <Container>
            <Header>
                {header}
                <Button onClick={handleClick} open={open}>
                    <BiDownArrow size={18} />
                </Button>
            </Header>
            {open && <Body>{children}</Body>}
        </Container>
    );
};

export default Accordion;

const Container = styled.div`
    box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 5px;
`;

const Header = styled.div`
    border-top: 2px solid #131432;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Button = styled.button`
    cursor: pointer;
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms;
    transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
`;

const open = keyframes`
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
`;

const Body = styled.div`
    border-top: 2px solid #131432;
    padding: 10px;
    animation: ${open} 0.5s ease;
`;
