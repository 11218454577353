import styled from "@emotion/styled";

import Goals from "../../components/goals/Goals";
import Groups from "../../components/groups/Groups";

import { useSelector } from "react-redux";

const Main = () => {
    const user = useSelector((store) => store.user.data);
    return (
        <Container>{user.role === "Ментор" ? <Groups /> : <Goals />}</Container>
    );
};

export default Main;

const Container = styled.div`
    margin: 96px auto 0 auto;
    @media (max-width: 768px) {
        margin: 52px auto 0 auto;
    }
`;

export { Container };
