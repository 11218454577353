import styled from "@emotion/styled";

import { Formik } from "formik";
import * as yup from "yup";

import { Input, InputMask } from "../UI/input/Input";
import Button from "../UI/button/Button";

import { Link, useNavigate } from "react-router-dom";

import request from "../../api/request";
import showToast from "../../helpers/showToast";

const FormRegistration = () => {
    const navigate = useNavigate();
    const registration = async (data) => {
        try {
            data.fio = data.secondName;
            delete data.secondName;
            data.phone_number = data.phone.replace(
                /^(\d{3})(\d{3})(\d{2})(\d{2})$/,
                "+7 ($1) $2-$3-$4"
            );
            delete data.phone;
            await request({
                endPoint: "register",
                method: "post",
                data,
            });
            showToast("Успешный запрос, проверьте почту", "success");
            navigate("/login");
        } catch (error) {
            showToast(error.response.data.detail, "error");
        }
    };
    const validationSchema = yup.object().shape({
        email: yup
            .string()
            .email("Некорректный email")
            .required("Обязательное поле"),
        password: yup.string().required("Обязательное поле"),
        secondName: yup
            .string()
            .matches(/^[а-яА-Я ]+$/iu, "Используйте кириллицу")
            .required("Обязательное поле"),
        phone: yup
            .string()
            .required("Обязательное поле")
            .min(10, "Заполните полностью")
            .max(10, "Заполните полностью"),
    });
    return (
        <>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    secondName: "",
                    phone: "",
                }}
                validateOnBlur
                onSubmit={(values) => {
                    registration(values);
                }}
                validationSchema={validationSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isValid,
                    handleSubmit,
                    setFieldValue,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Input
                            type={"email"}
                            name={"email"}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите адрес электронной почты"
                        />
                        {touched.email && errors.email && (
                            <Error>{errors.email}</Error>
                        )}
                        <Input
                            type={"password"}
                            name={"password"}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите пароль"
                        />
                        {touched.password && errors.password && (
                            <Error>{errors.password}</Error>
                        )}
                        <Input
                            type={"text"}
                            name={"secondName"}
                            value={values.secondName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Введите ФИО"
                        />
                        {touched.secondName && errors.secondName && (
                            <Error>{errors.secondName}</Error>
                        )}
                        <InputMask
                            type={"text"}
                            name={"phone"}
                            value={values.phone}
                            onChange={(e) => {
                                const value = e.target.value
                                    .replace(/\D/g, "")
                                    .replace(7, "");
                                setFieldValue("phone", value);
                            }}
                            onBlur={handleBlur}
                            placeholder="Введите номер телефона"
                            mask="+7 (999) 999-99-99"
                        />
                        {touched.phone && errors.phone && (
                            <Error>{errors.phone}</Error>
                        )}
                        <Button
                            disabled={!isValid}
                            type={"submit"}
                            style={{ width: "100%", marginTop: "12px" }}
                        >
                            Регистрация
                        </Button>
                    </Form>
                )}
            </Formik>
            <p>или</p>
            <Link to={"/login"}>Войти</Link>
        </>
    );
};

export default FormRegistration;

const Form = styled.form`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
`;

const Error = styled.p`
    font-size: 14px;
    color: red;
`;

export { Form, Error };
