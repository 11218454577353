import { useEffect, useState } from "react";

import { Body } from "../goals/Goals";
import Group from "./group/Group";

import request from "../../api/request";

const Groups = () => {
    const [activeItem, setActiveItem] = useState();
    const [groups, setGroups] = useState([]);
    const getGroups = async () => {
        try {
            const response = await request({
                endPoint: "groups",
                method: "get",
            });
            return response.data;
        } catch {
            return [];
        }
    };
    useEffect(() => {
        getGroups().then((data) => setGroups(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Body>
            {groups.map((group) => (
                <Group
                    group={group}
                    open={group.id === activeItem}
                    setOpen={() => setActiveItem(group.id)}
                    setClose={() => setActiveItem(false)}
                />
            ))}
        </Body>
    );
};

export default Groups;
