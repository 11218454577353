import styled from "@emotion/styled";

import { Link as LinkDom } from "react-router-dom";

import { useSelector } from "react-redux";

const Menu = () => {
    const user = useSelector((store) => store.user.data);
    return (
        <Body>
            {/* <Link to="/">Главная</Link>
            <Link to="/">Цели</Link> */}
            {user.role === "Пользователь" && (
                <>
                    <Link to="/">Главная</Link>
                    <Link to="/add-goal">Добавить цель</Link>
                    <Link to="/week">План на неделю</Link>
                </>
            )}
        </Body>
    );
};

export default Menu;

const Body = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 12px;
`;

const Link = styled(LinkDom)`
    font-weight: 600;
    font-size: 18px;
    color: white;
`;
