import styled from "@emotion/styled";
import { BiFootball } from "react-icons/bi";

const FormBody = ({ name, children }) => {
    return (
        <Background>
            <Title>
                <BiFootball />
                Target = Goal
            </Title>
            <Container>
                <Name>{name}</Name>
                <Body>{children}</Body>
            </Container>
        </Background>
    );
};

export default FormBody;

const Background = styled.div`
    width: 100%;
    min-height: 100vh;
    background: #f9fafc;
    padding: 64px 16px;
    @media (max-width: 640px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: white;
        padding: 24px 16px;
    }
`;

const Title = styled.h1`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 52px;
    font-weight: 700;
    margin-bottom: 42px;
    text-align: center;
    @media (max-width: 640px) {
        font-size: 32px;
        margin-bottom: 0;
    }
`;

const Container = styled.div`
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    border-radius: 3px;
    padding: 25px 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
    margin: 0 auto;
    @media (max-width: 640px) {
        box-shadow: none;
        margin: 0 auto;
        padding: 25px 0px;
    }
`;

const Body = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
`;

const Name = styled.p`
    text-align: center;
    color: #5e6c84;
    font-size: 16px;
    letter-spacing: -0.01em;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 25px;
    font-weight: 700;
`;
